<template>
  <div class="property-legal-documents">
    <div>
      <div class="section-title has-text-weight-semibold">Your documents</div>
      <div class="om-block is-relative is-clickable" @click="viewOM">
        <div class="is-flex is-align-items-center">
          <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/icons/file.svg" alt="Offering Memorandum">
          </div>
          <div class="doc-info">
            <div class="doc-name">{{ isAmerican ? 'Offering Circular' : (isCrowdfunding ? 'Offering Document' : 'Offering Memorandum')}}</div>
          </div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
      </div>
      <div class="om-block is-relative is-clickable" @click="viewFrenchOM" v-if="hasFrenchOM">
        <div class="is-flex is-align-items-center">
          <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/icons/file.svg" alt="Offering Memorandum">
          </div>
          <div class="doc-info">
            <div class="doc-name">Lire la notice d'offre</div>
          </div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
      </div>
      <div class="financial-statements-block is-relative is-clickable" @click="viewFinancialStatements" v-if="hasFinancialStatementsDocument">
        <div class="is-flex is-align-items-center">
          <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
            <img src="@assets/icons/file.svg" alt="Company Financial Statements">
          </div>
          <div class="doc-info">
            <div class="doc-name">Company Financial Statements</div>
          </div>
        </div>
        <img src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
      </div>
      <div class="purchase-block" v-for="purchase in documentList" :key="purchase.hashid">
        <div class="buy-id">ID: {{purchase.hashid}}</div>
        <!-- for legacy property - Trout Lake -->
        <template v-if="purchase.shareholderAgreement || purchase.subscriptionAgreement">
          <div
            :class="`sub-agreement is-relative has-margin-bottom ${canViewSignedDoc(purchase, 'shareholderAgreement') ? 'is-clickable' : ''}`"
            @click="viewSubAgreement(purchase, 'shareholder_agreement')"
          >
            <div class="is-flex is-align-items-center">
              <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
                <img src="@assets/icons/file.svg" alt="file">
              </div>
              <div class="doc-info is-flex is-flex-direction-column is-justify-content-center">
                <div class="doc-name">Shareholder Agreement</div>
                <div v-if="purchase.status === 'purchase_approved'" class="more-info">Countersigned {{purchase.agreementsXsignedAt | formatDate}}</div>
                <div v-else class="more-info">VOID</div>
              </div>
            </div>
            <img v-if="canViewSignedDoc(purchase, 'shareholderAgreement')" src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
          </div>
          <div
            :class="`sub-agreement is-relative ${canViewSignedDoc(purchase, 'subscriptionAgreement') ? 'is-clickable' : ''}`"
            @click="viewSubAgreement(purchase, 'subscription_agreement')"
          >
            <div class="is-flex is-align-items-center">
              <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
                <img src="@assets/icons/file.svg" alt="file">
              </div>
              <div class="doc-info is-flex is-flex-direction-column is-justify-content-center">
                <div class="doc-name">Subscription Agreement</div>
                <div v-if="purchase.status === 'purchase_approved'" class="more-info">Countersigned {{purchase.agreementsXsignedAt | formatDate}}</div>
                <div v-else class="more-info">VOID</div>
              </div>
            </div>
            <img v-if="canViewSignedDoc(purchase, 'subscriptionAgreement')" src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
          </div>
        </template>

        <div
          :class="`sub-agreement is-relative ${canViewSignedDoc(purchase, 'allContracts') ? 'is-clickable' : ''}`"
          @click="viewSubAgreement(purchase, 'all_contracts')"
          v-else
        >
          <div class="is-flex is-align-items-center">
            <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
              <img src="@assets/icons/file.svg" alt="file">
            </div>
            <div class="doc-info is-flex is-flex-direction-column is-justify-content-center">
              <div class="doc-name">Subscription Agreement</div>
              <div v-if="purchase.status === 'token_escrowed'" class="more-info">Signatures required</div>
              <div v-else-if="purchase.status === 'agreements_signed'" class="more-info">Signed {{purchase.agreementsSignedAt | formatDate}}</div>
              <div v-else-if="purchase.status === 'purchase_approved'" class="more-info">Countersigned {{purchase.agreementsXsignedAt | formatDate}}</div>
              <div v-else class="more-info">VOID</div>
            </div>
          </div>
          <img v-if="canViewSignedDoc(purchase, 'allContracts')" src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
        </div>

        <div v-if="purchase.status === 'token_escrowed'" class="signOrCancel">
          <div class="is-flex is-justify-content-space-between">
            <button class="button is-blue has-text-weight-semibold" @click="signSubAgreement(purchase)">Sign</button>
            <button class="button has-text-weight-semibold has-text-blue has-border-blue" @click="openCancellingModal(purchase.hashid)">Cancel</button>
          </div>
        </div>

        <div :class="`share-certificate is-relative ${canViewSignedDoc(purchase, 'shareCertificate') ? 'is-clickable' : ''}`" @click="viewCertificate(purchase)">
          <div class="is-flex is-align-items-center">
            <div class="doc-icon is-flex is-justify-content-center is-align-items-center">
              <img src="@assets/icons/certificate.svg" alt="certificate">
            </div>
            <div class="doc-info">
              <div class="doc-name">Certificate</div>
            </div>
          </div>
          <img v-if="canViewSignedDoc(purchase, 'shareCertificate')" src="@assets/icons/arrow-right.svg" alt="arrow" class="arrow">
        </div>

      </div>
    </div>
    <div :class="`modal cancelling-modal ${showCancellingModal ? 'is-active' : ''}`">
      <div class="modal-background"></div>
      <div class="modal-content has-background-white">
        <img src="@assets/icons/close.svg" alt="close" class="icon-close" @click="showCancellingModal = false">
        <div class="modal-header has-text-centered">Cancel investment? 😢</div>
        <div class="modal-text">Help us improve! 😊 We would love to know your reason for cancelling your investment. </div>
        <textarea v-model="cancellationReasons" class="textarea" placeholder="Your reason here (optional)..."></textarea>
        <div class="is-flex is-justify-content-space-between buttons">
          <b-button class="button-cancel has-text-weight-bold" @click="showCancellingModal = false">Back</b-button>
          <b-button class="is-blue button-submit has-text-weight-bold" @click="submitCancellation">Submit</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
import { embeddedInIos, isAndroid, isAmericanUser } from '@utils/common-methods/common'
import { getLegalDocumentsStatus, cancelPurchase } from '@api/portfolio'
import { fetchDocUrl } from '@api/common'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      documentList: [],
      showCancellingModal: false,
      cancellationReasons: undefined,
      buyIdForCancellation: undefined,
    }
  },
  props: {
    hasFrenchOM: {
      type: Boolean,
      default: false
    },
    purchaseProcessType: {
      type: String,
      validator(value) {
        return ['crowdfunding', 'offering_memorandum'].includes(value)
      },
    },
    hasFinancialStatementsDocument: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    propertyId() {
      return this.$route.params.propertyId
    },
    corporationId() {
      return this.$route.query.corporationId
    },
    embeddedByIos() {
      return embeddedInIos()
    },
    isAmerican() {
      return isAmericanUser()
    },
    isCrowdfunding() {
      return this.purchaseProcessType === 'crowdfunding'
    },
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
  },
  created() {
    this.getDocuments()
  },
  filters: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MMMM d, yyyy')
    },
  },
  methods: {
    getDocuments() {
      getLegalDocumentsStatus(this.propertyId).then((data) => {
        if (!data.success) return
        this.documentList = data.data.investments
      })
    },
    /**
    * Function Check if document is available for viewing
    * @param {Object} data - document status payload object
    * @param {String} documentType - camelCase doc type for object value mapping
    * @return {Boolean}
    */
    canViewSignedDoc(data, documentType) {
      if (
        data.status === 'subscription_agreement_signed' ||
        data.status === 'shareholder_agreement_signed' ||
        data.status === 'agreements_signed' ||
        data.status === 'purchase_approved'
      ) return !!data[documentType]
      return false
    },
    signSubAgreement(purchase) {
      this.$router.push(`/${this.nameSpaceUrl}/buy/sign/${this.propertyId}?buyId=${purchase.hashid}`)
    },
    async getFileUrl(modelName, id, documentType) {
      let w = null
      if (!this.embeddedByIos) w = window.open('')
      const response = await fetchDocUrl({
        model_name: modelName,
        hashid: id,
        document_type: documentType,
      })
      if (!this.embeddedByIos && (!response.success || response.data.url === null)) {
        return w.close()
      }
      if (!this.embeddedByIos) {
        w.location.href = response.data.url
        isAndroid() && setTimeout(() => {
          w && w.close()
        }, 2000)
      } else response.data.url && window.webkit.messageHandlers.openURL.postMessage(response.data.url)
    },
    viewOM() {
      this.getFileUrl('Corporation', this.corporationId, this.isCrowdfunding ? 'crowd_funding_document' : 'offering_memorandum_document')
    },
    viewFrenchOM() {
      this.getFileUrl('Corporation', this.corporationId, 'french_offering_memorandum_document')
    },
    viewFinancialStatements() {
      this.getFileUrl('Corporation', this.corporationId, 'financial_statements_document')
    },
    viewSubAgreement(purchase, documentType) {
      // https://stackoverflow.com/a/52551910 ES6
      const camelCaseType = documentType.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
      if (!this.canViewSignedDoc(purchase, camelCaseType)) return
      this.mixpanel.track('2mv4hhvx')
      this.getFileUrl('Buy', purchase.hashid, documentType)
    },
    viewCertificate(purchase) {
      if (!this.canViewSignedDoc(purchase, 'shareCertificate')) return
      this.mixpanel.track('fvffyng9')
      this.getFileUrl('Buy', purchase.hashid, 'encrypted_share_certificate')
    },
    submitCancellation() {
      cancelPurchase(this.propertyId, this.buyIdForCancellation, this.cancellationReasons).then((result) => {
        if (!result.success) return
        this.showCancellingModal = false
        this.getDocuments()
        this.$emit('didCancelInvestment')
      })
    },
    openCancellingModal(buyId) {
      this.cancellationReasons = undefined
      this.showCancellingModal = true
      this.buyIdForCancellation = buyId
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.property-legal-documents {
  padding: 0 25px;
  .section-title {
    font-size: 20px;
    line-height: 19px;
  }
  .om-block,
  .financial-statements-block {
    margin-top: 25px;
  }
  .om-block,
  .financial-statements-block,
  .purchase-block {
    padding-bottom: 25px;
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(142, 133, 170, 0.25);
    }
    .buy-id {
      color: #9185AE;
      font-size: 16px;
      margin-left: 15px;
      padding: 20px 0;
    }
    .doc-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #F0EEF8;
    }
    .sub-agreement.has-margin-bottom {
      margin-bottom: 20px;
    }
    .share-certificate {
      margin-top: 20px;
    }
    .signOrCancel {
      padding: 16px 13px 0 54px;
      .button {
        width: calc(50% - 6px);
        height: 43px;
        border-radius: 12px;
        &.has-border-blue {
          border-color: #4122a0;
        }
      }
    }
    .doc-info {
      padding-left: 14px;
      .doc-name {
        font-size: 20px;
        line-height: 24px;
      }
      .more-info {
        margin-top: 4px;
        font-size: 16px;
        line-height: 19px;
      }
    }
    .arrow {
      position: absolute;
      right: 0;
      top: 14px;
      padding-right: 10px;
    }
  }
  .cancelling-modal {
    z-index: 101;
    .modal-content {
      width: 332px;
      position: relative;
      border-radius: 12px;
      padding: 34px 30px 24px;
      .icon-close {
        width: 30px;
        position: absolute;
        left: 12px;
        top: 12px;
      }
      .modal-header {
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        line-height: 20px;
      }
      .textarea {
        margin-top: 16px;
        margin-bottom: 26px;
        height: 176px;
      }
      .buttons {
        .button-cancel {
          width: calc(50% - 8px);
          font-size: 16px;
          height: 43px;
          border-radius: 12px;
          border-color: #4A26AA;
        }
        .button-submit {
          width: calc(50% - 8px);
          font-size: 16px;
          height: 43px;
          border-radius: 12px;
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .property-legal-documents .cancelling-modal {
    .modal-content {
      width: $mobile-wrapper-width;
    }
  }
}
</style>
<style scoped>
.button-cancel >>> span {
  color: #4A26AA;
}
</style>
