<template>
  <div class="property-updates">
    <div class="section-title has-text-weight-semibold">Read updates</div>
    <div class="updates-swiper-box" v-if="showUpdate">
      <div class="updates-swiper-container">
        <div :class="`swiper-wrapper updates-swiper ${list.length === 1 ? 'only-one-update' : ''}`">
          <div
            v-for="(update, index) in list"
            :key="index"
            class="swiper-slide is-clickable"
          >
            <div class="new-flag" v-if="!update.viewedAt">
              <img src="@assets/icons/star.svg" alt="icon-top" class="icon-top">
              <span class="has-text-weight-semibold has-text-blue">NEW!</span>
              <img src="@assets/icons/star.svg" alt="icon-top" class="icon-bottom">
            </div>
            <div class="tag has-text-blue has-text-weight-semibold">{{update.updateType}}</div>
            <div class="update-date">{{update.updateDate | formatDate}}</div>
            <div v-if="update.updateCoverPhotoUrl">
              <div class="cover" :style="`background: url('${update.updateCoverPhotoUrl}') center/cover no-repeat`"></div>
              <div class="content">
                <div class="update-title has-text-weight-semibold">{{update.updateTitle}}</div>
                <div class="update-content">
                  <div class="text" v-html="update.updateText"></div>
                </div>
                <div class="read-more-mask">
                  <div class="read-more-text has-text-centered">read more</div>
                </div>
              </div>
            </div>
            <div class="content has-more-padding-top" v-else>
              <div class="update-title has-text-weight-semibold">{{update.updateTitle}}</div>
              <div class="update-content">
                <div class="text" v-html="update.updateText"></div>
              </div>
              <div class="read-more-mask has-more-padding-bottom">
                  <div class="read-more-text has-text-centered has-more-padding-bottom">read more</div>
                </div>
            </div>
          </div>
        </div>
        <div class="updates-swiper-navigation" v-if="!$isMobile && list.length > 1">
          <div :class="`swiper-button-prev ${navigationPrevDisabled}`" @click="slidePrev"></div>
          <div :class="`swiper-button-next ${navigationNextDisabled}`" @click="slideNext"></div>
        </div>
      </div>
    </div>
    <div class="updates-empty-state" v-else>
      <div class="background">
        <div class="text">
          <div class="has-text-centered has-text-weight-semibold">There are no</div>
          <div class="has-text-centered has-text-weight-semibold">updates yet!</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPropertyUpdates, updatePropertyUpdatesFlags } from '@api/portfolio'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      list: [],
      updatesSwiper: undefined,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    navigationPrevDisabled() {
      if (!this.updatesSwiper) return
      return this.updatesSwiper.activeIndex === 0 ? ' disabled' : ''
    },
    navigationNextDisabled() {
      if (!this.updatesSwiper) return
      return this.updatesSwiper.activeIndex === this.list.length - 1 ? ' disabled' : ''
    },
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
    showUpdate() {
      return this.list.length > 0
    },
    investorCountry() {
      return JSON.parse(localStorage.getItem('investor')).country
    },
    investorAdministrativeAreaLevel1() {
      return JSON.parse(localStorage.getItem('investor')).administrativeAreaLevel1
    },
  },
  props: {
    propertyNickname: {
      type: String,
      required: true
    },
    propertyName: {
      type: String,
      required: true
    },
  },
  filters: {
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MMMM d, yyyy')
    },
  },
  mounted() {
    this.getUpdates()
  },
  methods: {
    getUpdates() {
      getPropertyUpdates(this.$route.params.propertyId).then((data) => {
        if (!data.success) return
        this.list = data.data.propertyUpdates
        if (this.list.length === 0) return
        this.$nextTick(() => {
          this.initializeSwiper()
        })
        this.membershipPaid = data.data.membershipPaid
      })
    },
    initializeSwiper() {
      this.updatesSwiper = new Swiper('.updates-swiper-container', {
        initialSlide: 0,
        slidesPerView: 1.2,
        spaceBetween: 12,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
      document.querySelector('.updates-swiper').addEventListener('click', () => {
        this.mixpanel.track('gzs72x2a')
        const updateId = this.list[this.updatesSwiper.realIndex].id
        setTimeout(() => {
          updatePropertyUpdatesFlags(this.$route.params.propertyId, updateId)
          this.toUpdateDetails(updateId)
        }, 200)
      })
    },
    toUpdateDetails(updateId) {
      this.$router.push({
        path: `/portfolio/${this.nameSpaceUrl}/property/${this.$route.params.propertyId}/propertyUpdate/${updateId}`,
        query: {
          nickname: encodeURIComponent(this.propertyNickname),
          propertyName: encodeURIComponent(this.propertyName),
        }
      })
    },
    slidePrev() {
      this.updatesSwiper.slidePrev()
    },
    slideNext() {
      this.updatesSwiper.slideNext()
    },
  },
}
</script>
<style lang="scss" scoped>
.property-updates {
  padding-bottom: 28px;
  .section-title {
    font-size: 20px;
    line-height: 19px;
    padding-bottom: 30px;
    padding-left: 25px;
  }
  .updates-swiper-box {
    padding: 0 25px;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    .updates-swiper-container {
      position: relative;
      height: 309px;
      .swiper-wrapper {
        height: 100%;
        &.only-one-update .swiper-slide {
          width: 100% !important;
          margin-right: 0;
        }
        .swiper-slide {
          height: 100%;
          border-radius: 12px;
          background-color: #F0EEF8;
          overflow: hidden;
          .new-flag {
            position: absolute;
            left: 12px;
            top: 9px;
            width: 92px;
            height: 31px;
            line-height: 31px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            text-align: center;
            .icon-top {
              position: absolute;
              top: -2px;
              left: 12px;
            }
            span {
              font-size: 14px;
            }
            .icon-bottom {
              position: absolute;
              right: 12px;
              bottom: -2px;
            }
          }
          .tag {
            font-size: 14px;
            min-width: 92px;
            height: 31px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 6px;
            position: absolute;
            right: 12px;
            top: 9px;
          }
          .update-date {
            font-size: 14px;
            position: absolute;
            left: 24px;
            bottom: 16px;
            z-index: 10;
          }
          .cover {
            height: 100px;
          }
          .content {
            position: relative;
            height: 100%;
            width: 100%;
            padding: 16px 24px 0;
            &.has-more-padding-top {
              padding-top: 60px;
            }
            .update-title {
              font-size: 16px;
              line-height: 19px;
              margin-bottom: 8px;
            }
            .update-content {
              font-size: 16px;
              line-height: 19px;
              overflow: hidden;
              height: 110px;
              ::v-deep a {
                font-weight: 600;
                color: #9185AE;
              }
              ::v-deep b {
                font-weight: bold;
              }
              ::v-deep i {
                font-style: italic;
              }
            }
            .read-more-mask {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              padding: 16px 0;
              background: linear-gradient(rgba(255, 255, 255, 0), #f0eef8 90%);
              .read-more-text {
                width: 100%;
                position: absolute;
                font-size: 12px;
                color: #9185AE;
                bottom: 0;
                left: 0;
              }
            }
            .has-more-padding-bottom {
              padding-bottom: 55px;
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0.3;
        color: #4A26AA !important;
        transform: translateY(-50%);
        &.disabled {
          opacity: 0.3 !important;
          background: none !important;
          cursor: unset;
        }
        &:hover {
          background: #f6f5fc86;
          opacity: 1;
        }
      }
    }
  }
  .updates-empty-state {
    padding: 0 25px;
    .background {
      height: 309px;
      background: url('../../../assets/images/portfolio/portfolio-empty-state.svg') center/contain no-repeat;
      border: 1px solid #F0EEF8;
      border-radius: 12px;
      .text {
        margin-top: 125px;
        font-size: 16px;
        line-height: 19px;
        > div {
          color: #9185AE;
        }
      }
    }
  }
}
</style>
