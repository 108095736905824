<template>
  <div>
    <div class="future-value-graph">
      <div class="section-title has-text-weight-semibold">{{graphName}}</div>
      <div class="tooltip is-inline-flex is-align-items-center is-clickable" @click="showFutureValueModal = true">
        <img src="@assets/icons/info.svg" alt="tooltip">
        <span class="tooltip-label">About this graph</span>
      </div>
      <div class="graph has-text-centered">
        <span class="graph-wrap is-inline-block is-relative">
          <img src="@assets/images/portfolio/future-value.png" alt="future-value-graph" class="future-value-graph">
          <span class="begin-dot"></span>
          <span class="end-dot-wrap">
            <b-tooltip position="is-left" :active="showGraphTooltip" multilined always>
              <span class="end-dot is-inline-block is-clickable" @click="showGraphTooltip = !showGraphTooltip"></span>
              <template v-slot:content>
                <div class="has-text-weight-semibold has-text-left roi">+{{estimatedRoi}}% ROI*</div>
                <div class="has-text-weight-semibold has-text-left">+${{realisticProfit | truncate | formatCurrency}}</div>
              </template>
            </b-tooltip>
          </span>
        </span>
      </div>
      <div class="graph-label is-flex is-justify-content-space-between">
        <span>Today</span>
        <span>Year {{investmentTerm}}</span>
      </div>
      <div class="captions has-text-left">
        <div class="optimistic">
          <span class="circle"></span>
          <span class="name">Optimistic: </span>
          <span class="value">${{realisticProfit * 1.1 | truncate | formatCurrency}}</span>
        </div>
        <div class="realistic">
          <span class="circle"></span>
          <span class="name">Realistic: </span>
          <span class="value">${{realisticProfit | truncate | formatCurrency}}</span>
        </div>
        <div class="pessimistic">
          <span class="circle"></span>
          <span class="name">Pessimistic: </span>
          <span class="value">${{realisticProfit * 0.9 | truncate | formatCurrency}}</span>
        </div>
      </div>
    </div>
    <StandardModal
      :showModal="showFutureValueModal"
      :closeModal="closeFutureValueModalAction"
      :buttonTappedAction="closeFutureValueModalAction"
      title="Future Value Graph"
      buttonTitle="Close"
      >
      <div class="modal-text">
        <div class="mb-4">
          Projections are based on the projected cash flow (expected distributions and estimated appreciation of the property).
          The ‘optimistic’ return is based on a +10% increase on the cash flows per year. The ‘pessimistic’ return is based on a -10% decrease on the cash flows per year.
        </div>
        <div class="mb-1">
          *We cannot predict the real estate market, so actual returns may differ and the graph is for visualization purposes only.
          You are not liable beyond your investment amount.
        </div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import StandardModal from '@components/modal/standard-modal.vue'
import { truncate, formatter } from '@utils/common-methods/numberFormatter'

export default {
  data() {
    return {
      showFutureValueModal: false,
      showGraphTooltip: true,
    }
  },
  computed: {
    realisticProfit() {
      return this.estimatedRoi / 100 * this.totalInvestedAmount
    },
  },
  props: {
    graphName: {
      type: String,
      default: 'Projected future value'
    },
    estimatedRoi: {
      type: Number,
      default: 0,
    },
    totalInvestedAmount: {
      type: Number,
      default: 0,
    },
    investmentTerm: {
      type: Number,
      default: 0,
    },
  },
  components: {
    StandardModal,
  },
  filters: {
    truncate(num) {
      return truncate(num, 2)
    },
    formatCurrency(value) {
      return formatter(value)
    },
  },
  methods: {
    closeFutureValueModalAction() {
      this.showFutureValueModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.future-value-graph {
  .section-title {
    font-size: 20px;
    padding-left: 25px;
    padding-bottom: 30px;
  }
  .tooltip {
    padding-left: 25px;
    .tooltip-label {
      font-size: 16px;
      margin-left: 8px;
    }
  }
  .graph {
    .future-value-graph {
      width: 324px;
    }
    .begin-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #4A26AA;
      position: absolute;
      left: -10px;
      bottom: 2px;
    }
    .end-dot-wrap {
      position: absolute;
      right: -6px;
      top: 40%;
      .roi {
        padding-top: 5px;
        padding-bottom: 6px;
      }
    }
    .end-dot {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #4A26AA;
      border: 2px solid #FFF;
    }
  }
  .graph-label {
    padding: 14px 8px 20px 25px;
    font-size: 14px;
  }
  .captions {
    font-size: 16px;
    padding-left: 100px;
    > div {
      margin-bottom: 4px;
    }
    .circle {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .optimistic .circle {
      background: #3CDBC0;
    }
    .realistic .circle {
      background: #4A26AA;
    }
    .pessimistic .circle {
      background: #D79133;
    }
    .name {
      display: inline-block;
      margin-left: 8px;
      width: 100px;
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .future-value-graph {
    span.graph-wrap {
      width: 80%;
      .future-value-graph {
        width: 100%;
      }
    }
    .graph-label {
      padding-left: 8%;
      padding-right: 8%;
    }
    .captions {
      width: fit-content;
      margin: 0 auto;
      padding-left: 0;
    }
  }
}
</style>
<style scoped>
.end-dot-wrap .b-tooltip >>> .tooltip-content {
  right: 26px;
  width: 108px;
  height: 61px;
  background: #F0EEF8;
  border-radius: 12px;
  box-shadow: none;
}
.end-dot-wrap .b-tooltip >>> .tooltip-content::before {
  border-color: transparent;
}
.end-dot-wrap .b-tooltip >>> .tooltip-content > div {
  font-size: 14px;
  line-height: 16px;
}
</style>
