<template>
  <div class="investment-details">
    <div class="sticky-header has-background-white" v-if="$isMobile">
      <MenuAndLogo :showReferral="true" :hasNavBack="true" :action="navBack"></MenuAndLogo>
    </div>
    <NavBack :action="navBack"/>
    <div v-if="!!buyId && isMobile" class="share-icon is-flex is-justify-content-center is-align-items-center" @click="toPortfolioSharingPage">
      <img src="@assets/icons/share.svg" alt="share-icon">
    </div>
    <div class="scrolling-part">
      <div class="page-header has-text-centered">
        <div class="property-nickname has-text-weight-semibold">{{propertyNickname}}</div>
        <div class="property-name">{{propertyName}}</div>
      </div>
      <DistributionPortfolio
        ref="recentDistribution"
        v-if="distributionData"
        :distributionData="distributionData"
        :totalDistributionData="totalDistributionData"
        :totalInvestments="totalInvestments"
      ></DistributionPortfolio>
      <div class="investment-metrics">
        <div class="is-flex is-justify-content-space-between has-margin">
          <div :class="`metric-block has-background-white has-text-centered ${saleStatus === 'settled' ? '' : 'full-width'}`">
            <div class="metric-title has-text-weight-semibold">Invested</div>
            <div class="has-padding">
              <span class="sign sup has-text-weight-semibold has-text-blue">$</span>
              <span class="value has-text-blue">{{totalInvestments | formatCurrency}}</span>
            </div>
          </div>
          <div class="metric-block has-background-white has-text-centered" v-if="saleStatus === 'settled'">
            <div class="metric-title has-text-weight-semibold">Ownership</div>
            <div>
              <span class="value has-text-blue">{{ownership}}</span>
              <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
            </div>
          </div>
        </div>
        <template v-if="totalDistributions">
          <div class="is-flex is-justify-content-space-between has-distributions">
            <div class="metric-block has-background-white has-text-centered">
              <div class="metric-title has-text-weight-semibold">Total Returns</div>
              <div class="has-padding">
                <span class="sign sup has-text-weight-semibold has-text-blue">$</span>
                <span class="value has-text-blue">{{totalDistributions | truncateNumber | formatCurrency}}</span>
              </div>
            </div>
            <div class="metric-block has-background-white is-relative irr-and-roi" v-if="bothRoiAndIrrArePresent">
              <div class="metrics-swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide has-text-centered">
                    <div class="metric-title has-text-weight-semibold">Estimated IRR</div>
                    <div>
                      <span class="value has-text-blue">{{estimatedIrr ? estimatedIrr : 'n/a'}}</span>
                      <span class="sign sub has-text-weight-semibold has-text-blue" v-if="estimatedIrr">%</span>
                    </div>
                  </div>
                  <div class="swiper-slide has-text-centered">
                    <div class="metric-title has-text-weight-semibold">Estimated ROI</div>
                    <div>
                      <span class="value has-text-blue">{{estimatedRoi}}</span>
                      <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <img src="@assets/icons/switch.svg" alt="switch-icon" class="switch-icon is-clickable" @click="switchMetrics">
            </div>
          </div>
        </template>
        <template v-if="!totalDistributions && bothRoiAndIrrArePresent">
          <div class="is-flex is-justify-content-space-between has-margin">
            <div class="metric-block has-background-white has-text-centered">
              <div class="metric-title has-text-weight-semibold">Estimated ROI</div>
              <div>
                <span class="value has-text-blue">{{estimatedRoi}}</span>
                <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
              </div>
            </div>
            <div class="metric-block has-background-white has-text-centered">
              <div class="metric-title has-text-weight-semibold">Estimated IRR</div>
              <div>
                <span class="value has-text-blue">{{estimatedIrr}}</span>
                <span class="sign sub has-text-weight-semibold has-text-blue">%</span>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="graph-wrap" v-if="term && estimatedRoi">
        <FutureValueGraph
          graphName="Future value"
          :estimatedRoi="Number(estimatedRoi)"
          :totalInvestedAmount="Number(totalInvestments)"
          :investmentTerm="term"
        ></FutureValueGraph>
      </div>
      <div class="transactions">
        <div class="section-title has-text-weight-semibold">Your transactions</div>
        <div
          class="transaction is-relative"
          v-for="(record, index) in transactionsList" :key="index"
        >
          <div class="is-flex is-align-items-center">
            <div class="transaction-icon-bg is-flex is-justify-content-center is-align-items-center">
              <img
                :src="`https://addy-public.s3.us-west-2.amazonaws.com/${record.type === 'Investment' ? 'investment-icon' : 'dollar-icon'}.svg`"
                alt="transaction icon">
            </div>
            <div class="type-of-transaction">
              <div class="type">{{record.type}}</div>
              <div class="id" v-if="record.type === 'Investment'">ID: {{record.hashid}}</div>
              <div class="date">{{record.createdAt | formatDate}}</div>
            </div>
          </div>
          <div class="amount has-text-weight-semibold is-flex is-align-items-center">{{record.type === 'Investment' ? '- ' : '+ '}}${{record.amount | truncateNumber | formatCurrency}}</div>
        </div>
      </div>
      <div class="property-updates-wrap">
        <PropertyUpdatesList :propertyNickname="propertyNickname" :propertyName="propertyName"></PropertyUpdatesList>
      </div>
      <div class="legal-docs-wrap">
        <LegalDocuments
          :hasFinancialStatementsDocument="hasFinancialStatementsDocument"
          @didCancelInvestment="fetchData"
          :hasFrenchOM="hasFrenchOM"
          :purchaseProcessType="purchaseProcessType"></LegalDocuments>
      </div>
      <div class="mini-prop has-text-centered">
        <img :src="miniPropUrl" alt="mini-prop">
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { formatter, truncate } from '@utils/common-methods/numberFormatter'
import { mobileCheck } from '@utils/common-methods/common'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import DistributionPortfolio from './DistributionPortfolio.vue'
import FutureValueGraph from '../share/FutureValueGraph.vue'
import PropertyUpdatesList from './PropertyUpdatesList.vue'
import LegalDocuments from './LegalDocuments.vue'
import axios from '@lib/axios/middleware'
import { getInvestmentsSummary, getPropertyRelatedTransactions } from '@api/portfolio'
import { DateTime } from 'luxon'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      propertyName: '',
      propertyNickname: '',
      totalInvestments: '',
      ownership: '',
      estimatedRoi: '',
      estimatedIrr: '',
      term: 0,
      metricSwiper: null,
      saleStatus: '',
      buyId: '',
      transactionsList: [],
      miniPropUrl: '',
      hasFrenchOM: false,
      purchaseProcessType: '',
      hasFinancialStatementsDocument: false,
      distributionData: undefined,
      totalDistributionData: undefined,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    isMobile() {
      return mobileCheck()
    },
    bothRoiAndIrrArePresent() {
      return this.estimatedRoi && this.estimatedIrr
    },
    totalDistributions() {
      if (!this.totalDistributionData) return ''
      return this.totalDistributionData.ownedDistributionAmount
    },
    nameSpaceUrl() {
      return this.$route.params.nameSpaceUrl
    },
    distributionId() {
      return this.$route.query.distributionId
    },
  },
  components: {
    MenuAndLogo,
    NavBack,
    DistributionPortfolio,
    FutureValueGraph,
    PropertyUpdatesList,
    LegalDocuments,
  },
  filters: {
    formatCurrency(value) {
      return formatter(value)
    },
    truncateNumber(num) {
      return truncate(Number(num), 2)
    },
    formatDate(date) {
      return DateTime.fromISO(date).toFormat('MMMM d, yyyy')
    },
  },
  mounted() {
    this.fetchData()
    this.fetchTransactions()
    this.mixpanel.track('4bh38qbx')
  },
  methods: {
    fetchData() {
      const propertyId = this.$route.params.propertyId
      const payload = { distribution_id: this.distributionId }
      axios.all([
        getInvestmentsSummary(propertyId, payload),
      ]).then((result) => {
        if (result[0].success) {
          this.handleInvestmentsSummaryData(result[0].data)
          this.$nextTick(() => {
            this.totalDistributions && this.initializeMetricsSwiper()
          })
        } else {
          // disable axios error flash and redirect back to portfolio
          this.$router.push(`/portfolio`)
        }
      })
    },
    fetchTransactions() {
      getPropertyRelatedTransactions(this.$route.params.propertyId).then((data) => {
        if (!data.success) return
        this.transactionsList = data.data.transactions
      })
    },
    initializeMetricsSwiper() {
      this.metricSwiper = new Swiper('.metrics-swiper-container', {
        initialSlide: 0,
      })
    },
    switchMetrics() {
      if (this.metricSwiper.activeIndex) this.metricSwiper.slidePrev()
      else this.metricSwiper.slideNext()
    },
    toPortfolioSharingPage() {
      this.mixpanel.track('cenbxvy8')
      this.$router.push(`/portfolio/${this.nameSpaceUrl}/share?transactionId=${this.buyId}`)
    },
    setTotalDistributionObject(distributionData) {
      const distributionObj = {}
      for (const key in distributionData) {
        if (Object.hasOwnProperty.call(distributionData, key)) {
          distributionObj[this.parseKey(key)] = distributionData[key]
        }
      }
      return distributionObj
    },
    handleInvestmentsSummaryData(data) {
      const {
        propertyName, propertyNickname, totalInvestments, ownership, term, estimatedRoi,
        estimatedIrr, saleStatus, buyId, miniPropUrl, hasFinancialStatementsDocument, hasFrenchOM, purchaseProcessType,
        ...totalDistributionData
      } = data.summary
      if (data.distribution) this.distributionData = data.distribution
      this.propertyName = propertyName
      this.propertyNickname = propertyNickname
      this.totalInvestments = totalInvestments
      this.ownership = ownership
      this.estimatedRoi = estimatedRoi
      this.estimatedIrr = estimatedIrr
      this.term = term
      this.saleStatus = saleStatus
      this.buyId = buyId
      this.miniPropUrl = miniPropUrl
      this.hasFinancialStatementsDocument = hasFinancialStatementsDocument
      this.hasFrenchOM = hasFrenchOM
      this.purchaseProcessType = purchaseProcessType
      this.totalDistributionData = this.setTotalDistributionObject(totalDistributionData)
    },
    parseKey(string) {
      if (string === 'totalDistributions') return 'ownedDistributionAmount'
      const removedTotal = string.replace('total', '')
      return removedTotal.charAt(0).toLowerCase() + removedTotal.slice(1)
    },
    navBack() {
      this.$router.replace('/portfolio')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.investment-details {
  background-color: #FDFDFD;
  padding-top: 70px;
  overflow: auto;
  .sticky-header {
    width: 100%;
    height: 68px;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .share-icon {
    position: fixed;
    right: 20px;
    bottom: 90px;
    z-index: 100;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #F0EEF8;
    border: 1px solid #fff;
  }
  .page-header {
    width: 100%;
    padding: 20px 6px 10px;
    .property-nickname {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 4px;
    }
    .property-name {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .scrolling-part {
    padding: 0 0 80px;
    .investment-metrics {
      padding: 20px 25px;
      > .has-margin {
        margin-bottom: 12px;
      }
      .metric-block {
        width: calc(50% - 6px);
        height: 97px;
        border-radius: 12px;
        box-shadow: 0px 4px 50px rgba(215, 145, 51, 0.11);
        overflow: hidden;
        &.full-width {
          width: 100%;
        }
        .placeholder {
          font-size: 30px;
          padding-top: 1px;
        }
        .switch-icon {
          position: absolute;
          right: 6px;
          top: 4px;
          z-index: 2;
          padding: 4px;
        }
        .metrics-swiper-container {
          height: 100%;
        }
        .metric-title {
          font-size: 16px;
          margin-top: 20px;
          margin-bottom: 10px;
        }
        .has-padding {
          padding-top: 1px;
        }
        .sign {
          font-size: 20px;
          &.sup {
            vertical-align: top;
            line-height: 26px;
            margin-right: 2px;
          }
          &.sub {
            vertical-align: bottom;
            line-height: 23px;
            margin-left: 2px;
          }
        }
        .value {
          font-size: 32px;
        }
      }
      .has-distributions {
        .metric-block {
          flex: 1;
          width: unset;
          &.irr-and-roi {
            margin-left: 12px;
          }
        }
      }
    }
    .graph-wrap {
      padding-bottom: 28px;
    }
    .section-title {
      font-size: 20px;
      line-height: 19px;
      padding-bottom: 30px;
    }
    .transactions {
      padding: 0 25px 10px;
      .transaction {
        margin-bottom: 30px;
        .transaction-icon-bg {
          width: 40px;
          height: 40px;
          background-color: #F0EEF8;
          border-radius: 50%;
        }
        .type-of-transaction {
          padding-left: 14px;
          .type {
            font-size: 20px;
            padding-bottom: 4px;
          }
          .id,
          .date {
            font-size: 16px;
            line-height: 19px;
          }
          .id {
            padding-bottom: 4px;
          }
        }
        .amount {
          font-size: 16px;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
        }
      }
    }
    .mini-prop {
      img {
        width: 180px;
        margin-top: 40px;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .investment-details {
    padding-top: 0;
    background-color: transparent;
    .fixed-header {
      position: unset;
      box-shadow: none;
      background-color: transparent;
    }
    .scrolling-part {
      padding-top: 0;
    }
  }
}
</style>
