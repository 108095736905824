<template>
  <div class="distribution-portfolio pb-5 is-relative">
    <div class="distribution-tabs-swiper-container">
      <div class="distribution-tabs section-title has-text-centered has-text-weight-semibold is-flex is-justify-content-flex-start">
        <div
          :class="`${hasMultipleDistributions ? 'tab' : ''} ${firstTabIsActive ? 'active' : 'is-clickable'}`"
          @click="switchTabs(1)">{{firstTabTitle}}
        </div>
        <div
          v-if="hasMultipleDistributions"
          :class="`tab ${firstTabIsActive ? 'is-clickable' : 'active'}`"
          @click="switchTabs(2)">{{secondTabTitle}}
        </div>
      </div>
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <Distribution
            distributionType="individual-latest"
            :totalInvestments="totalInvestments"
            v-bind="latestDistribution"
          >
          </Distribution>
        </div>
        <div class="swiper-slide" v-if="hasMultipleDistributions">
          <Distribution
            distributionType="individual-total"
            :totalInvestments="totalInvestments"
            v-bind="totalDistribution"
          >
          </Distribution>
        </div>
      </div>
    </div>
    <div class="has-text-centered pt-5">
      <ShareButton
        buttonText="Spread the Word!"
        :shareText="shareText"
        :embeddedInIosAction="embeddedInIosAction"
      ></ShareButton>
    </div>
    <lottie-player
      class="confetti"
      autoplay
      mode="normal"
      speed="0.5"
      src="https://assets4.lottiefiles.com/packages/lf20_ymmwsjwv.json"
      style="width: 100%; height: 280px;"
      v-if="showLottie"
    >
    </lottie-player>
  </div>
</template>
<script>
import Distribution from '@views/portfolio/index/Distribution.vue'
import ShareButton from '@components/button/share-button.vue'
import '@lottiefiles/lottie-player'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'
import { DateTime } from 'luxon'

export default {
  data() {
    return {
      assetsSwiper: undefined,
      showLottie: true,
    }
  },
  props: {
    distributionData: {
      type: Object,
      required: true
    },
    totalDistributionData: {
      type: Object,
      required: true
    },
    totalInvestments: {
      type: String,
      required: true
    },
  },
  computed: {
    hasMultipleDistributions() {
      return this.totalDistributionData.ownedDistributionAmount !== this.distributionData.ownedDistributionAmount
    },
    firstTabIsActive() {
      return this.assetsSwiper && this.assetsSwiper.activeIndex === 0
    },
    firstTabTitle() {
      if (this.distributionId) {
        return this.firstTabIsActive ? `${this.distributionDate} Distribution` : this.distributionDate
      }
      return this.firstTabIsActive ? 'Latest Distribution' : 'Latest'
    },
    secondTabTitle() {
      return this.firstTabIsActive ? 'Total' : 'Total Distributions'
    },
    hasWebShare() {
      return !!navigator.share
    },
    latestDistribution() {
      const { rentalIncome, returnOfCapital, capitalGains, businessIncome, ownedDistributionAmount } = this.distributionData
      return { rentalIncome, returnOfCapital, capitalGains, businessIncome, ownedDistributionAmount }
    },
    totalDistribution() {
      const { rentalIncome, returnOfCapital, capitalGains, businessIncome, ownedDistributionAmount } = this.totalDistributionData
      return { rentalIncome, returnOfCapital, capitalGains, businessIncome, ownedDistributionAmount }
    },
    shareText() {
      return 'My addy property just paid me a distribution! 💰 Start earning with addy today using this link and get $25 towards your next investment.'
    },
    embeddedInIosAction() {
      if (!window.webkit) return () => {}
      return () => {
        window.webkit.messageHandlers.shareOwnersDay.postMessage(this.shareText)
      }
    },
    distributionId() {
      return this.$route.query.distributionId
    },
    distributionDate() {
      return DateTime.fromISO(this.distributionData.dateOfDistribution).toFormat('MMM d, yyyy')
    },
  },
  components: {
    Distribution,
    ShareButton,
  },
  mounted() {
    this.initializeCarousel()
    setTimeout(() => {
      this.showLottie = false
    }, 3000)
  },
  methods: {
    initializeCarousel() {
      this.assetsSwiper = new Swiper('.distribution-tabs-swiper-container', {
        allowTouchMove: this.hasMultipleDistributions,
        initialSlide: 0,
      })
    },
    slidePrev() {
      if (this.assetsSwiper.isBeginning) return
      this.assetsSwiper.slidePrev()
    },
    slideNext() {
      if (this.assetsSwiper.isEnd) return
      this.assetsSwiper.slideNext()
    },
    switchTabs(to) {
      if (this.assetsSwiper.activeIndex + 1 === to) return
      if (to === 2) return this.assetsSwiper.slideNext()
      this.assetsSwiper.slidePrev()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
$background: #F0EEF8;

.distribution-portfolio {
  .section-title {
    font-size: 20px;
    padding: 30px 25px;
    padding-bottom: 0px;
  }
  .distribution-tabs {
    > .tab {
      background-color: $background;
      &.is-clickable {
        background-color: unset;
      }
      opacity: 0.6;
      padding: 5px;
      border-bottom: 2px solid $background;
    }
    > .tab:hover {
      opacity: 1;
    }
    .active {
      padding: 5px 10px;
      opacity: 1;
    }
  }
  .distribution-tabs-swiper-container {
    overflow: hidden;
  }
  .confetti {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
  }
  ::v-deep .share-button {
    font-size: 20px;
  }
}
</style>
